import { useEffect, useRef, useState } from 'react';
import ReactScrollEffect from 'react-scroll-effect';

import webDevIcon from './assets/webdev-icon.png';
import chevron from './assets/chevron-down-sm.svg';
import profilePic from './assets/profile.jpg';
import bjornPic from './assets/bjorn_review.png';
import linkedinIcon from './assets/linkedin.svg';
import emailIcon from './assets/email.svg';
import phoneIcon from './assets/phone.svg';

function App() {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    setScrollContainer(scrollContainerRef?.current);
  }, [scrollContainerRef]);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className='bg-gray-100 h-screen w-screen overflow-auto overflow-x-hidden' ref={scrollContainerRef}>

      <ReactScrollEffect.Container
        scrollContainer={scrollContainer}
        scrollDistance={windowHeight}
        containerClassName='w-full h-full flex portrait:flex-wrap items-center justify-center'
      >
        {(scrollPercentage) => (
          <>
            <ReactScrollEffect.Animation
              percentage={scrollPercentage}
              animationClassName='animate-rollAwayLeft'
            >
              <>
                <img src={webDevIcon} alt='' className='md:hidden portrait:w-11/12 landscape:w-7/12 max-w-[24rem]' />
                <img src={webDevIcon} alt='' className='hidden md:block w-96' />
              </>
            </ReactScrollEffect.Animation>

            <ReactScrollEffect.Animation
              percentage={scrollPercentage}
              animationClassName='animate-rollAwayRight'
            >
              <div className='text-teal-500 text-center flex flex-col items-center justify-center my-20 px-6 md:px-12'>
                <span className='text-6xl'>
                  vicolsson.se
                </span>
                <span className='text-2xl'>
                  Freelance Web-dev Consultant
                </span>
              </div>
            </ReactScrollEffect.Animation>

            <ReactScrollEffect.Animation
              percentage={scrollPercentage}
              animationClassName='animate-lateFadeOut'
            >
              <div className='w-full flex justify-center absolute bottom-0 left-0 animate-blinking'>
                <img src={chevron} alt='Scroll down for more' className='w-36 m-[-2rem]' />
              </div>
            </ReactScrollEffect.Animation>
          </>
        )}
      </ReactScrollEffect.Container>

      <ReactScrollEffect.Container
        scrollContainer={scrollContainer}
        scrollDistance={windowHeight}
        containerClassName='w-full h-full flex portrait:flex-wrap items-center justify-center bg-teal-500'
      >
        {(scrollPercentage) => (
          <>
            <ReactScrollEffect.Animation
              percentage={scrollPercentage}
              animationClassName='animate-veryLateFadeOut'
            >
              <>
                <img src={profilePic} alt='' className='rounded-full drop-shadow-md md:hidden portrait:w-11/12 landscape:w-7/12 max-w-[24rem]' />
                <img src={profilePic} alt='' className='rounded-full drop-shadow-md hidden md:block w-96' />
              </>
            </ReactScrollEffect.Animation>

            <ReactScrollEffect.Animation
              percentage={scrollPercentage}
              animationClassName='animate-veryLateFadeOut'
            >
              <div className='text-white text-center flex flex-col items-center justify-center my-20 px-6 md:px-12'>
                <span className='text-6xl'>
                  Victor Olsson
                </span>
                <span className='text-2xl'>
                  Full-stack Web Developer
                </span>
              </div>
            </ReactScrollEffect.Animation>

            <div className='text-white text-center w-full absolute bottom-4 lg:bottom-12 left-0'>
              <span className='text-3xl'>
                With love for the frontend ❤️
              </span>
            </div>
          </>
        )}
      </ReactScrollEffect.Container>

      <ReactScrollEffect.Container
        scrollContainer={scrollContainer}
        scrollDistance={windowHeight}
        containerClassName='w-full h-full flex flex-wrap items-center justify-center bg-white'
      >
        {(scrollPercentage) => (
          <ReactScrollEffect.Animation
            percentage={scrollPercentage}
            animationClassName='animate-scaleUp'
          >
            <img src={bjornPic} alt='' className='rounded-lg drop-shadow-lg w-11/12 max-w-[800px]' />
          </ReactScrollEffect.Animation>
        )}
      </ReactScrollEffect.Container>

      <div className='h-screen w-screen flex flex-col items-center justify-center'>
        <div>
          <div className='text-3xl mb-4'>Victor Olsson Consulting</div>
          <div>
            <div className='text-2xl mb-4'>Växjö, Sweden</div>
            <div className='text-2xl mb-4 flex items-center'>
              <img src={emailIcon} alt='' className='w-8 mr-2' />
              victor@vicolsson.se
            </div>
            <div className='text-2xl mb-4 flex items-center'>
              <img src={phoneIcon} alt='' className='w-8 mr-2' />
              070-22 13 179
            </div>
            <div className='text-2xl'>
              <a
                href='https://www.linkedin.com/in/vicolsson/'
                target="_blank"
                rel='noreferrer'
                className='flex items-center'
              >
                <img src={linkedinIcon} alt='' className='w-8 mr-2' />
                linkedin.com/in/vicolsson/
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
